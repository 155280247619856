<template>
  <div class="particulars">
    <CompanyHeader titleurl="news" v-if="!isfromoa" />
    <div class="particulars-center">
      <div class="product">
        <div class="product-content">
          <div class="wnews">
            <ul>
              <li v-for="(item, i) in newlist" :key="i">
                <div class="item-new">
                  <b class="newtitle" @click="getdetail(item.id)">{{
                    item.title
                  }}</b>
                  <span class="newdata">{{
                    item.createDate != null && item.createDate.length > 0
                      ? item.createDate.substring(5, 10)
                      : ''
                  }}</span>
                </div>
              </li>
            </ul>
            <div class="page">
              <el-pagination
                @size-change="handlecustomSizeChange"
                background
                @current-change="handlecustomCurrentChange"
                :current-page="pageIndex"
                :page-sizes="[12, 24, 36]"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer v-if="!isfromoa" />
  </div>
</template>
<script>
import { companygetcompanyinfo, getnewlist } from '@/api/company.js';
import Footer from '@/components/footer.vue';
import CompanyHeader from '@/components/companyheader.vue';

export default {
  components: {
    CompanyHeader,
    Footer
  },
  data() {
    return {
      isfromoa: false,
      newlist: [],
      cid: '',
      cmpinfo: {},
      type: '',
      pageIndex: 1,
      pageSize: 12,
      totalCount: 0
    };
  },
  methods: {
    getdetail(val) {
      this.$router.push({
        path: '/company/newsdetail',
        query: { id: this.cid, newsid: val }
      });
    },
    handlecustomCurrentChange(item) {
      this.pageIndex = item;
      this.ongetnewlist();
    },
    handlecustomSizeChange(item) {
      this.pageSize = item;
      this.ongetnewlist();
    },
    ongetnewlist() {
      let paramnews = {
        categoryId: 300,
        sortRule: 0,
        companyId: this.cid,
        keyword: '',
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      getnewlist(paramnews).then(res => {
        if (res.data.code == 1) {
          this.newlist = res.data.result.list;
          this.totalCount = res.data.result.totalCount;
        }
      });
    },
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        console.log(res);
        this.cmpinfo = res.data.result;
      });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.oncompanygetcompanyinfo();
    this.ongetnewlist();
  },
  created() {
    //console.warn('query.id', this.$route.query);
    if (this.$route.query.id === '409') {
      //言天下
      this.$router.push({
        path: `/company/ytx_newslist`,
        query: { id: this.$route.query.id }
      });
    }
  }
};
</script>
<style scoped>
.page {
  margin-top: 30px;
  text-align: center;
}
.wnews {
  margin: 10px 0 20px 30px;
}
.wnews .item-new {
  padding: 15px 2px;
  border-bottom: 1px #c9c9c9 dotted;
}
.wnews .newtitle {
  width: 520px;
  font-size: 13px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.wnews .newtitle:hover {
  text-decoration: underline;
  color: #4169e1;
}
.wnews .newdata {
  font-size: 13px;
  float: right;
  color: #b7b7b7;
}
.particulars {
  width: 100%;
  background: #fff;
}

.particulars-center {
  width: 100%;
  min-width: 1280px;
}
.product {
  max-width: 1200px;
  margin: 0 auto;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
</style>
